<!-- 图片粘贴上传 -->
<template>
  <div class="paste-upload">
    <div class="paste-upload__mag">
      <el-button size="mini" type="primary" plain :disabled="disabled" @click="openCard">图片粘贴上传</el-button>
      <slot name="butbox"></slot>
    </div>
    <el-dialog title="图片粘贴上传" :visible.sync="dialogVisible" width="600px" :close-on-click-modal="false" append-to-body>
      <div contenteditable="true" ref="editor" class="paste-upload__editor" @paste.prevent="onPaste" @input.prevent="changeContent"></div>
      <div class="paste-upload__tip">
        使用方法：复制图片后在框内<span class="highlighted">鼠标右键点击粘贴</span>或者<span class="highlighted">Ctrl+v</span>粘贴
        <div>
          当前限制图片大小不能超过<span class="highlighted">{{ maxSizeText }}</span
          >、一次性上传不能超过<span class="highlighted" v-if="limit > 0">{{ limit }}张</span>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="onClose" size="mini">取 消</el-button>
        <el-button :loading="uploadLoading" type="primary" @click="onUpload" size="mini">上传</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import ajax from './ajax';
export default {
  name: 'PasteUpload',
  inheritAttrs: false,
  data() {
    return {
      uploadLoading: false,
      dialogVisible: false,
      fileMap: new Map(),
      fileList: [] // 文件集合
    };
  },
  computed: {
    maxSizeText() {
      return filterBytes(this.maxSize);
    }
  },
  methods: {
    // 请求
    post(rawFile) {
      return new Promise((resolve, reject) => {
        const options = {
          headers: this.headers,
          withCredentials: this.withCredentials,
          file: rawFile,
          data: this.postData,
          filename: this.name,
          action: this.action,
          onProgress: (e) => {
            this.onProgress && this.onProgress(e, rawFile);
          },
          onSuccess: (res) => {
            return resolve(res);
          },
          onError: (err) => {
            this.onError && this.onError(err, rawFile);
            return reject(err);
          }
        };
        this.httpRequest(options);
      });
    },
    // 打开弹窗
    openCard() {
      if (this.dialogVisible || this.disabled) return;
      this.dialogVisible = true;
    },
    // 关闭弹窗
    onClose() {
      this.dialogVisible = false;
      this.$refs.editor.innerHTML = '';
      this.fileMap.clear(); // 清空
    },
    // 上传
    async onUpload() {
      this.uploadLoading = true;
      const list = this.getValue();
      if (list && list.length) {
        if (this.exceed(list)) {
          // 数量限制校验
          this.uploadLoading = false;
          return;
        }
        const imgList = [];
        for (const i of list) {
          const res = await this.post(i.file).catch((err) => {
            this.uploadLoading = false;
            throw new Error(err);
          });
          res?.data && imgList.push(res.data);
        }
        this.onSuccess(imgList);
        this.onClose();
      } else {
        this.$message.error('请粘贴图片');
      }
      this.uploadLoading = false;
    },
    // 获取并且截断粘贴事件
    async onPaste(e) {
      this.handlePaste(e)
        .then((arr) => {
          if (arr.length > 0) {
            arr.forEach((item) => {
              this.execCommandInsertImage(item);
            });
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
    },
    // 写入图片
    execCommandInsertImage(file) {
      const className = 'paste-upload__img';
      if (document.execCommand) {
        document.execCommand('insertHTML', false, `<img src="${file.value}" class="${className}" />`);
      } else {
        const imgObj = document.createElement('img');
        imgObj.setAttribute('src', file.value);
        imgObj.className = className;
        this.$refs.editor.appendChild(imgObj);
      }
    },
    // 获取元素
    getValue() {
      return this.getDomValue(this.$refs['editor'], this.fileMap);
    },
    // 获取富文本图片
    getDomValue(elem, fileMap) {
      const images = [];
      Array.from(elem.childNodes).forEach((child) => {
        if (child.nodeName === 'IMG') {
          images.push({
            url: child.src,
            file: fileMap && fileMap.get(child.src)
          });
        }
      });
      return images;
    },
    // 捕获图片
    handlePaste(e) {
      if (!(e.clipboardData && e.clipboardData.items)) {
        const txt = '当前浏览器不支持粘贴';
        this.$message.error(txt);
        throw new Error(txt);
      }
      return new Promise((resolve, reject) => {
        const imageList = [];
        for (let i = 0, len = e.clipboardData.items.length; i < len; i++) {
          const item = e.clipboardData.items[i]; // getAsFile / type
          if (item.kind === 'file' && isImage(item.type)) {
            const pasteFile = item.getAsFile();
            if (!this.beforeUpload(pasteFile)) return; // 校验
            const url = URL.createObjectURL(pasteFile);
            this.fileMap.set(url, pasteFile);
            imageList.push({ type: 'image', value: url });
          }
        }
        if (imageList.length === 0) {
          const txt = '没有可以粘贴的图片或者图片超过大小';
          this.$message.error(txt);
          reject(txt);
          return;
        }
        resolve(imageList);
      });
    },
    // 监听文字输入并且清空输入的文字
    changeContent(e) {
      const illegal = ['insertText', 'insertCompositionText'];
      if (illegal.includes(e.inputType)) {
        // 过滤文字输入
        this.$refs.editor.innerText = '';
      }
    },
    // 上传前校验
    beforeUpload(file) {
      const less = file.size < this.maxSize;
      if (!less) {
        this.$notify.error(`【${file.name}】上传图片大小不能超过 ${this.maxSizeText}!`);
      }
      return less;
    },
    // 数量校验
    exceed(fileList) {
      if (this.limit > 0 && fileList.length > this.limit) {
        this.$notify.warning(`当前限制一次性上传最多 ${this.limit} 张图片，本次选择了 ${fileList.length} 张图片超出限制！`);
        return true;
      }
      return false;
    },
    onSuccess(list) {
      this.$message.success('上传成功');
      this.$emit('onSuccess', list);
    }
  },
  components: {},
  props: {
    disabled: {
      // 是否可编辑
      type: Boolean,
      default: false
    },
    limit: {
      // 最大数量
      type: Number,
      default: 9
    },
    maxSize: {
      // 最大体积
      type: Number,
      default: 1024 * 1024 * 2 // 默认2M
    },
    name: {
      // 文件name
      type: String,
      default: 'file'
    },
    httpRequest: {
      // 接口方法
      type: Function,
      default: ajax
    },
    action: {
      // 接口URL
      type: String,
      required: true
    },
    onProgress: Function, // 上传进度信息
    onError: Function, // 错误信息
    headers: Object, // 请求头
    postData: Object, // 携带的参数
    withCredentials: Boolean // 指定在涉及到跨域请求时，是否携带cookie信息，默认值为false
  }
};
function filterBytes(count) {
  const units = ['B', 'KB', 'MB', 'GB'];
  let i = 0;
  while (count > 1024 && i < units.length) {
    count = count / 1024;
    i++;
  }
  count = Math.round(count * 100) / 100;
  return `${count}${units[i]}`;
}
function isImage(str) {
  return str.indexOf('image') !== -1;
}
</script>
<style lang="scss">
.paste-upload {
  &__editor {
    background: #f8f8f8;
    height: 200px;
    overflow: auto;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    padding: 10px;
  }
  &__img {
    width: 146px;
    height: 146px;
    margin-right: 10px;
  }
  &__tip {
    line-height: 1.6;
    margin-top: 5px;
    .highlighted {
      color: red;
    }
  }
  &__mag {
    margin-bottom: 6px;
  }
}
</style>

