<template>
  <div class="picture_card_crypto">
    <div class="picture_card_crypto--header" v-if="isHhow">
      <PasteUpload v-if="!disabled && realLimit" @onSuccess="gerImgList" :limit="realLimit" :headers="headers" :action="action"> </PasteUpload>
    </div>
    <div class="picture_card_crypto--view">
      <PictureCardCryp :disabled="disabled" :limit="realLimit" :value="value" @input="cardInput" :accept="accept" v-bind="$attrs" isSlotFile>
        <div slot="file" slot-scope="{ file }">
          <li class="default_image-box--li">
            <template v-if="isNotImgFile(file.url)">
              <div class="default_image-box--file" v-if="isNotImgFile(file.url)">{{ file.name || '查看附件' }}</div>
              <img v-else class="default_image-box--img" :src="file.url" />
            </template>
            <img v-else class="default_image-box--img" :src="fn(file)" />
            <label class="el-upload-list__item-status-label">
              <i
                :class="{
                  'el-icon-upload-success': true,
                  'el-icon-circle-check': listType === 'text',
                  'el-icon-check': ['picture-card', 'picture'].indexOf(listType) > -1
                }"
              ></i>
            </label>
            <span class="default_image-box--back">
              <span class="default_image-box--back__item-preview" @click="onPreview(file)" v-if="!isNotImgFile(file.url)">
                <i class="el-icon-zoom-in"></i>
              </span>
              <span class="default_image-box--back__item-download" @click="onDownload(file)" v-if="isNotImgFile(file.url)">
                <i class="el-icon-download"></i>
              </span>
              <span v-if="!disabled" class="default_image-box--back__item-delete" @click="onRemove(file)">
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </li>
        </div>
      </PictureCardCryp>
    </div>
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img :src="dialogImageUrl" alt width="100%" />
    </el-dialog>
  </div>
</template>
<script>
// 图片上传加密解密组件
import PictureCardCryp from '@/components/Upload/PictureCardCryp';
import PasteUpload from '@/components/Upload/PasteUpload';
import { getHeadersWithExtTenant } from '@/utils/request';
import uniqueId from 'lodash/uniqueId';
import { mapGetters } from 'vuex';
export default {
  name: 'picture-card-crypto',
  data() {
    return {
      listType: 'picture-card',
      action: `${process.env.VUE_APP_BASE_API}/file-service/api/image/upload`,
      dialogVisible: false,
      dialogImageUrl: '', // 预览
      cryptoImg: 'https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/pc/crypto-img.png', // 加密图片
      isDecryption: false, // 是否解密
      whiteList: ['png', 'jpg', 'jpeg', 'gif', 'webp']
    };
  },
  watch: {},
  computed: {
    ...mapGetters(['userInfo']),
    realLimit() {
      // 真实数量限制
      return this.limit - this.value.length;
    },
    headers() {
      const { userToken = '' } = this.userInfo || {};
      return {
        ...getHeadersWithExtTenant(),
        'X-User-Token': userToken
      };
    }
  },
  methods: {
    isNotImgFile(url) {
      if(!url){
        return true;
      }
      return this.whiteList.indexOf(url.substring(url.lastIndexOf('.') + 1)) === -1;
    },
    fn(file) {
      return file.url;
    },
    // 查看
    onPreview(item) {
      // if (item.response) {
        // 自己上传的
        this.dialogImageUrl = item.url;
        this.dialogVisible = true;
      // } else {
        // 历史数据
        // this.dialogImageUrl = this.isDecryption ? item.url : this.cryptoImg;
        // this.dialogVisible = true;
      // }
    },
    // 下载
    onDownload(item) {
      window.location.href = item.url;
    },
    cardInput(arr) {
      this.$emit('input', arr);
    },
    // 删除
    onRemove(file) {
      const fileList = this.value;
      fileList.splice(fileList.indexOf(file), 1);
      this.cardInput(fileList);
    },
    // 获取图片粘贴上传
    gerImgList(arr) {
      this.$emit('input', [
        ...this.value,
        ...arr.map((url) => ({
          url,
          uid: uniqueId('paste-upload_')
        }))
      ]);
    }
  },
  components: {
    PictureCardCryp,
    PasteUpload
  },
  props: {
    disabled: {
      // 是否可编辑
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default() {
        return [];
      }
    },
    isHhow: {
      // 是否显示粘贴按钮
      type: Boolean,
      default: true
    },
    limit: Number,
    accept: {
      type: String,
      default: '.jpeg,.png,.jpg'
    }
  }
};
</script>
<style lang="scss" scoped>
.picture_card_crypto {
  &--view {
    display: flex;
    flex-wrap: wrap;
  }
  &--header {
    display: flex;
    align-items: center;
    min-height: 42px;
  }
}
.to-view-btn {
  margin-left: 10px;
  margin-bottom: 6px;
}
ul {
  list-style: none; /*清除列表默认样式*/
  padding: 0; /*清除padding*/
}
.default_image-box {
  margin: 0;
  display: flex;
  vertical-align: top;
  flex-wrap: wrap;
  &--li {
    overflow: hidden;
    background-color: #fff;
    border-radius: 6px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 148px;
    height: 148px;
    margin: 0 8px 8px 0;
    display: inline-block;
    position: relative;
  }
  &--img {
    width: 100%;
    height: 100%;
  }
  &--file{
    padding: 0 8px;
    height: 100%;
    line-height: 150%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &--label {
    display: block;
    position: absolute;
    right: -15px;
    top: -6px;
    width: 40px;
    height: 24px;
    background: #13ce66;
    text-align: center;
    transform: rotate(45deg);
    // box-shadow: 0 0 1px 1px rgb(0 0 0 / 20%);
    color: #fff;
    line-height: inherit;
    font-size: 14px;
    i {
      font-size: 12px;
      margin-top: 11px;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
  &--back {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: default;
    text-align: center;
    color: #fff;
    opacity: 0;
    font-size: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      cursor: pointer;
    }
    &:hover {
      opacity: 1;
    }
    &:hover span {
      display: inline-block;
    }
    &:hover + .default_image-box .default_image-box--label {
      display: none;
    }
    .el-icon-delete {
      margin-left: 15px;
    }
  }
  &--back__item-download {
    margin-left: 15px;
  }
}
</style>
