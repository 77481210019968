<template>
  <div>
    <div class="illustrate">
        <p>填写说明</p>
        <p>1、联行号查询地址:<a :href="interBankNo" target="_black">{{ interBankNo }}</a></p>
        <p>2、企业账户需要上传开户行许可证、个人账户可以上传收款银行确认函。点击<el-button type="text" class="download" @click="downloadPromiseTemplate">下载《账户信息确认函模板》</el-button>
         </p>
      </div>
  </div>
</template>

<script>
export default {
  name: 'illustrateExplain',
  data() {
    return {
      interBankNo: 'https://www.icvio.cn/',
    };
  },
  methods: {
    downloadPromiseTemplate() {
      window.location.href = `${process.env.VUE_APP_MUSHUROOMFILEURL}/static/file/soyoung-zg/template/%E8%B4%A6%E6%88%B7%E4%BF%A1%E6%81%AF%E7%A1%AE%E8%AE%A4%E5%87%BD.docx`;
    },
  }
};
</script>

<style lang="scss" scoped>
.illustrate {
  width: 870px;
  padding: 10px 20px;
  background: rgba(16, 16, 16, 0.05);
  p {
    font-size: 14px;
    color: #606266;
    font-weight: 700;
    line-height: 24px;
  }
  a,
  .download {
    color: #409eff;
    font-weight: 700;
    font-size: 14px;
  }
}
</style>